<template>
    <v-card class="pt-3">
        <v-card-subtitle class="d-flex justify-space-between align-center px-4 pb-3 px-0">
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    density="compact"
                    color="primary"
                    :label="item.label"
                    v-model="curItem.selected"
                    @change="$emit('update', curItem)"
                />
            </div>
            <div class="icons-action">
                <v-icon @click="active = !active" class="icon-action mr-4" :class="{active}">
                    mdi-chevron-down
                </v-icon>
                <v-icon @click="$emit('copy', curItem)" class="icon-action mr-4">
                    mdi-content-copy
                </v-icon>
                <v-icon @click="$emit('remove')" class="icon-action">
                    mdi-close
                </v-icon>
            </div>
        </v-card-subtitle>
        <slide-up-down v-model="active">
            <div class="py-2 px-4">
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('key')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.key"
                    label="Ключ значения"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('itemsKey')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.itemsKey"
                    label="Ключ списка"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    hide-details
                    density="compact"
                    v-model="curItem.data.col"
                    label="Кол-во колонок"
                    type="number"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('label')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.label"
                    label="Лейбл"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('disabled')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.disabled"
                    label="Дизейблед"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('mask')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.mask"
                    label="Маска"
                    class="mb-2"
                ></v-text-field>
                <v-text-field
                    v-if="curItem.data.hasOwnProperty('headers')"
                    hide-details
                    density="compact"
                    v-model="curItem.data.headers"
                    label="Заголовки"
                    class="mb-2"
                ></v-text-field>
                <v-checkbox
                    v-if="curItem.data.hasOwnProperty('draggable')"
                    hide-details
                    density="compact"
                    label="Draggable"
                    class="mb-2"
                    v-model="curItem.data.draggable"
                >
                </v-checkbox>
                <div v-if="curItem.data.hasOwnProperty('elementProps')">
                    <v-card-subtitle class="px-0">Пропсы:</v-card-subtitle>
                    <v-code class="mb-2">
                        {{ curItem.data.elementProps }}
                    </v-code>
                    <v-btn text size="small" variant="outlined" @click="openEditProps(curItem.data.elementProps, 'elementProps')">
                        <v-icon>mdi-pencil</v-icon>
                        Редактировать пропсы
                    </v-btn>
                </div>
                <div v-if="curItem.data.hasOwnProperty('additionalProps')">
                    <v-card-subtitle class="px-0">Доп. пропсы:</v-card-subtitle>
                    <v-code class="mb-2">
                        {{ curItem.data.additionalProps }}
                    </v-code>
                    <v-btn text size="small" variant="outlined" @click="openEditProps(curItem.data.additionalProps, 'additionalProps')">
                        <v-icon>mdi-pencil</v-icon>
                        Редактировать пропсы
                    </v-btn>
                </div>
            </div>
            <v-btn block color="#ececec" @click="save">
                Сохранить
            </v-btn>
        </slide-up-down>
        <v-dialog
            v-model="activeEditProps"
            width="800"
        >
            <v-card width="800">
                <v-card-title>Редактирование пропсов</v-card-title>
                <v-sheet class="px-4 py-4">
                    <v-row no-gutters v-for="(item, i) in propsList" :key="i">
                        <v-col cols="4">
                            <v-text-field
                                hide-details
                                density="compact"
                                v-model="item.key"
                                label="Ключ"
                                class="mb-2 mr-2"
                            ></v-text-field>
                        </v-col>
                        :
                        <v-col cols="4">
                            <v-text-field
                                hide-details
                                density="compact"
                                v-model="item.value"
                                label="Значение"
                                class="mb-2 ml-2"
                            ></v-text-field>
                        </v-col>
                        <v-btn icon
                               variant="outlined" color="green" class="ml-4"
                               @click="propsList.push({key: '', value: ''})">
                            <v-icon color="green">mdi-plus</v-icon>
                        </v-btn>
                        <v-btn icon
                               @click="propsList.splice(i, 1)"
                               variant="outlined" color="red" class="ml-4" v-if="i !== 0">
                            <v-icon color="red">mdi-minus</v-icon>
                        </v-btn>
                    </v-row>
                </v-sheet>
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-space-between px-4 py-1">
                    <v-btn
                        variant="outlined"
                        color="blue"
                        @click="saveProps"
                    >
                        Сохранить
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="activeEditProps = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import SlideUpDown from 'vue3-slide-up-down'
import {ref} from "vue";
import {useToast} from "vue-toastification";

export default {
    name: "dynamicalItem",
    components: {
        SlideUpDown
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {
        const activeEditProps = ref(false)
        const propsList = ref([{key: '', value: ''}])
        const propsType = ref('')
        const toast = useToast();
        const active = ref(false)
        const curItem = ref(JSON.parse(JSON.stringify(props.item)))
        const save = () => {
            emit('update', curItem.value);
            active.value = false
            toast.success("Сохранено!");
        }
        const openEditProps = (curProps, type) => {
            if (type) {
                propsType.value = type
            }
            let props = {...curProps}
            if (Object.keys(props)) {
                for (let o in props) {
                    propsList.value.push({key: o, value: props[o]})
                }
            }
            activeEditProps.value = true
        }
        const saveProps = () => {
            propsList.value.forEach(elem => {
                if (elem.key)
                    if (propsType.value === 'additionalProps') {
                        curItem.value.data.additionalProps[elem.key] = elem.value
                    } else {
                        curItem.value.data.elementProps[elem.key] = elem.value
                    }
            })
            activeEditProps.value = false
            propsList.value = [{key: '', value: ''}]
        }
        return {
            saveProps,
            openEditProps,
            save,
            propsList,
            curItem,
            activeEditProps,
            active,
            propsType
        }
    }
}
</script>

<style scoped>
.icon-action {
    cursor: pointer;
    transition: transform .25s ease-out;
}

.icon-action.active {
    transform: rotate(180deg);
}
</style>
