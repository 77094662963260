import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

loadFonts()

createApp(App)
  .use(Toast, {
    timeout: 2000
  })
  .use(vuetify)
  .mount('#app')
