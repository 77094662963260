<template>
<div class="controller">
  <v-divider/>
  <div class="px-4 py-4 controller-content d-flex justify-space-between">
    <div class="d-flex align-center">
      <v-btn color="deep-purple" class="mr-4" @click="$emit('generate')">Сгенерировать JSON</v-btn>
      <v-btn @click="$emit('clear')">Очистить</v-btn>
    </div>
    <v-btn @click="$emit('close')">Закрыть</v-btn>
  </div>
</div>
</template>

<script>
export default {
  name: "ControllerBlock"
}
</script>

<style scoped>
.controller {
  height: 73px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
    z-index: 5;
}
.controller-content {
  background-color: white;
  border-right: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));;
}
</style>
