<template>
    <v-row>
        <v-col cols="4">
            <div class="text-h6 my-3">Компонент</div>
            <v-btn
                v-if="!data.hasOwnProperty('path')"
                color="primary"
                class="my-4"
                @click="addField(data,'path', '')"
            >
                Добавить путь
            </v-btn>
            <v-select
                v-if="data.path || data.hasOwnProperty('path')"
                hide-details
                label="Путь"
                :items="['iterators/index.vue', 'nestedPages/index.vue', 'nestedPages/contentComponent/default.vue', 'nestedPages/dynamic/index.vue']"
                v-model="data.path"
                @update:modelValue="selectProps"
            />
        </v-col>
    </v-row>

    <v-expansion-panels class="py-4" v-if="(data.hasOwnProperty('path') || data.path) && data.path !== 'nestedPages/contentComponent/default.vue' && data.path !== 'nestedPages/dynamic/index.vue'">
        <v-row>
            <v-col cols="6" v-for="(part, key, i) in data.props"
                    :key="i">
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        {{ key }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <componentPart
                            v-model="data.props[key]"
                            :paths="selectPath(key)"
                            :type="key"
                        />
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-col>
        </v-row>
    </v-expansion-panels>
</template>

<script>
import {toRef, watch} from 'vue'
import componentPart from "@/components/tabs/componentPart";
export default {
    name: 'tabComponent',
    components: {
        componentPart
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {
        const data = toRef(props, "modelValue")
        const selectProps = () => {
            if (data.value.path === 'nestedPages/index.vue')
                data.value.props = {topComponent: {},contentComponent:{}, bottomComponent:{}}
            else
                data.value.props = {topComponent: {},filterComponent:{}, itemComponent:{}, beforeListingComponent:{}, footerComponent:{}}
        }
        const selectPath = (index) => {
            if (data.value.path === 'nestedPages/index.vue') {
                if (index === 'topComponent')
                    return [false, "nestedPages/topComponent/default"]
                else if (index === 'contentComponent')
                    return [false, 'nestedPages/contentComponent/default', 'nestedPages/contentComponent/cars', 'nestedPages/contentComponent/communications', 'nestedPages/contentComponent/items', 'nestedPages/contentComponent/page', 'nestedPages/contentComponent/pdf', 'nestedPages/contentComponent/personal', 'nestedPages/contentComponent/seo', 'nestedPages/contentComponent/tickets']
                else if (index === 'bottomComponent')
                    return [false, "nestedPages/bottomComponent/default"]
            } else if (data.value.path === 'iterators/index.vue') {
                if (index === 'topComponent')
                    return [false, 'iterators/topComponent/default.vue']
                else if (index === 'filterComponent')
                    return [false, 'iterators/filterComponent/default.vue', 'iterators/filterComponent/datasheet.vue']
                else if (index === 'itemComponent')
                    return [false, 'iterators/itemComponent/car.vue', 'iterators/itemComponent/datasheet.vue', 'iterators/itemComponent/default.vue', 'iterators/itemComponent/page.vue']
                else if (index === 'beforeListingComponent')
                    return [false, 'iterators/beforeListing/default.vue']
                else if (index === 'footerComponent')
                    return [false, 'iterators/footerComponent/default.vue']
            }
        }
        const addField = (source, key, val) => {
            return {...source[key] = val}
        }
        watch(
            () => data,
            (val) => {
            emit('update:modelValue', val)
        },
            { deep: true }
        )
        return {
            addField,
            data,
            selectPath,
            selectProps
        }
    }
}
</script>