<template>
    <div>
        <v-row>
            <v-col>
                <v-select
                    :items="items"
                    label="Тип запроса"
                    v-model="newQuery"
                />
            </v-col>
            <v-col>
                <v-btn
                    v-if="newQuery"
                    elevation="2"
                    outlined
                    rounded="0"
                    color="primary"
                    @click="addQuery"
                    :disabled="queries.hasOwnProperty(newQuery.toLowerCase())"
                >
                    Добавить Query
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-for="(item, key, i) in queries" :key="i">
            <v-col cols="3">
                <v-text-field
                    label="URL"
                    v-model="item.url"
                    @input="$forceUpdate()"
                />
            </v-col>
            <v-col cols="3">
                <v-select
                    :items="methods"
                    label="МЕТОД"
                    v-model="item.method"
                />
            </v-col>
            <v-col cols="3">
                <v-text-field
                    label="API"
                    v-model="item.api"
                    @input="$forceUpdate()"
                />
            </v-col>
            <v-col cols="3">
                <v-btn
                    elevation="2"
                    outlined
                    rounded="0"
                    color="error"
                    @click="delQuery(key)"
                >
                    Удалить Query
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {getCurrentInstance, ref, toRef, watch} from "vue";

export default {
    name: "queryComponent",
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup(props, {emit}) {
        const queries = toRef(props, 'modelValue')
        const items = ['get', 'delete', 'patch', 'create', 'export']
        const methods = ['POST', 'GET', 'PATCH', 'DELETE']
        const newQuery = ref('')
        const instance = getCurrentInstance();
        const addQuery = () => {
            let key = newQuery.value
            queries.value[key] = {
                url: '',
                method: ''
            }
            instance.proxy.$forceUpdate();
        }
        const delQuery = (key) => {
            delete queries.value[key]
            instance.proxy.$forceUpdate();
        }
        watch(
            () => queries,
            (val) => {
                emit('update:modelValue', val)
                instance.proxy.$forceUpdate();
            },
            { deep: true }
        )
        return {
            queries,
            newQuery,
            items,
            addQuery,
            delQuery,
            methods
        }
    }
}
</script>

<style scoped>

</style>