export const componentList = [
  {
    label: 'Заголовок',
    img: "img/components/subtitle.jpg",
    selected: false,
    data: {
      label: '',
      element: 'title',
      col: '6',
      elementProps: {},
    }
  },
  {
    label: 'Подзаголовок',
    img: "img/components/subtitle.jpg",
    selected: false,
    data: {
      label: '',
      element: 'subtitle',
      col: '6',
      elementProps: {},
    }
  },
  {
    label: 'Инпут',
    img: "img/components/input.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      mask: '',
      element: 'input',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Текстареа',
    img: "img/components/textarea.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'text',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Текстовый редактор',
    img: "img/components/editor.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'editor',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Свитч(переключатель)',
    img: "img/components/switch.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'switch',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Селект',
    img: "img/components/select.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      itemsKey: '',
      element: 'select',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Рендж',
    img: "img/components/slider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'slider',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Радио кнопки',
    img: "img/components/radio.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      itemsKey: '',
      element: 'radio',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Файл',
    img: "img/components/file.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'file',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Загрузка картинок',
    img: "img/components/image.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'images',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Дейтпикер',
    img: "img/components/datepicker.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'date',
      disabled: '',
      elementProps: {},
      additionalProps: {},
    }
  },
  {
    label: 'Шорткоды',
    img: "img/components/shortcode.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'shortcode',
      elementProps: {},
    }
  },
  {
    label: 'Карта',
    img: "img/components/map.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'map',
      countryKey: '',
      cityKey: '',
      streetKey: '',
      structureKey: '',
      elementProps: {}
    }
  },
  {
    label: 'Таймпикер',
    img: "img/components/timepicker.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'time',
      disabled: '',
      elementProps: {}
    }
  },
  {
    label: 'Разделитель',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      element: 'divider',
      col: '6',
      elementProps: {},
    }
  },
  {
    label: 'СелектЛист',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'selectList',
      elementProps: {},
    }
  },
  {
    label: 'ИнпутСлаг',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'inputSlug',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Динамический список',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      labelKey: '',
      labelValue: '',
      draggable: false,
      col: '6',
      key: '',
      element: 'dynamicList',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'КомбоБокс',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'combobox',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Таблица',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      headers: '',
      searchKey: '',
      element: 'table',
      disabled: '',
      elementProps: {},
    }
  },
  {
    label: 'Цвет',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      label: '',
      col: '6',
      key: '',
      element: 'color',
      elementProps: {},
      additionalProps: {}
    }
  },
  {
    label: 'Ряд',
    img: "img/components/divider.jpg",
    selected: false,
    data: {
      col: '6',
      element: 'row',
      content: [],
      elementProps: {}
    }
  },
]
