<template>
  <v-sheet
      color="white"
      elevation="2"
      rounded
      class="mb-4 overflow-hidden"
  >
    <h4 class="pl-3 pr-2 py-1 font-weight-light d-flex justify-space-between align-center">
      {{ item.label }}
      <v-btn
          x-small
          icon
          elevation="0"
          @click="$emit('preview', item.img)"
      >
        <v-icon size="15">mdi-image-multiple-outline</v-icon>
      </v-btn>
    </h4>
    <v-btn
        elevation="2"
        block
        outlined
        rounded="0"
        color="primary"
        @click="$emit('add', item)"
    >
      Добавить
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  name: "dynamicalComponent",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
