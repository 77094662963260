<template>
    <v-card height="800">
        <v-row no-gutters style="height: 100%">
            <v-col cols="10" class="d-flex flex-column" style="height: 100%; position: relative; padding-bottom: 73px">
                <v-card-subtitle class="px-4 py-4 d-flex justify-space-between align-center" style="height: 64px">
                    Контент страницы:
                    <div v-if="components.length" class="d-flex align-center">
                        <v-btn text size="small" variant="outlined" @click="openEditProps">
                            <v-icon>mdi-pencil</v-icon>
                            Редактировать пропсы для выбранных блоков
                        </v-btn>
                        <v-dialog
                            v-model="activeEditProps"
                            width="800"
                        >
                            <v-card width="800">
                                <v-card-title>Редактирование пропсов</v-card-title>
                                <v-sheet class="px-4 py-4">
                                    <v-row no-gutters v-for="(item, i) in propsList" :key="i">
                                        <v-col cols="4">
                                            <v-text-field
                                                hide-details
                                                density="compact"
                                                v-model="item.key"
                                                label="Ключ"
                                                class="mb-2 mr-2"
                                            ></v-text-field>
                                        </v-col>
                                        :
                                        <v-col cols="4">
                                            <v-text-field
                                                hide-details
                                                density="compact"
                                                v-model="item.value"
                                                label="Значение"
                                                class="mb-2 ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-btn icon
                                               variant="outlined" color="green" class="ml-4"
                                               @click="propsList.push({key: '', value: ''})">
                                            <v-icon color="green">mdi-plus</v-icon>
                                        </v-btn>
                                        <v-btn icon
                                               @click="propsList.splice(i, 1)"
                                               variant="outlined" color="red" class="ml-4" v-if="i !== 0">
                                            <v-icon color="red">mdi-minus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-sheet>
                                <v-divider></v-divider>
                                <v-card-actions class="d-flex justify-space-between px-4 py-1">
                                    <v-btn
                                        variant="outlined"
                                        color="blue"
                                        @click="saveProps()"
                                    >
                                        Сохранить
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="activeEditProps = false"
                                    >
                                        Закрыть
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                    <div class="d-flex">
                        <v-btn size="small" icon @click="loadModal = true">
                            <v-icon size="small">
                                mdi-upload
                            </v-icon>
                        </v-btn>
                        <v-btn size="small" icon class="ml-4" @click="saveLocalStorage">
                            <v-icon size="small">
                                mdi-content-save
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-subtitle>
                <v-divider/>
                <div class="py-4 px-4 overflow-y-auto">
                    <draggable v-model="components" class="v-row v-row--no-gutters" item-key="label">
                        <template #item="{element, index}">
                            <v-col
                                :cols="element.data.col"
                                class="px-2 py-2"
                            >
                                <dynamical-item
                                    :item="element"
                                    @remove="removeItem(index)"
                                    @update="updateItem($event, index)"
                                    @copy="copyItem($event, index)"
                                />
                            </v-col>
                        </template>
                    </draggable>
                </div>
                <controller
                    @close="$emit('close')"
                    @generate="generateJSON"
                    @clear="clearItems"
                />
            </v-col>
            <v-divider vertical/>
            <v-col cols="2" class="d-flex flex-column" style="height: 100%">
                <v-card-subtitle class="px-4 py-4 d-flex align-center" style="height: 64px">Компоненты:
                </v-card-subtitle>
                <v-divider/>
                <div class="py-4 px-4 overflow-y-auto">
                    <dynamical-component
                        v-for="(item, idx) in componentsList"
                        :key="idx"
                        :item="item"
                        @preview="openPreview"
                        @add="addItem"
                    />
                </div>
            </v-col>
        </v-row>
        <preview-modal
            v-model="componentPreview"
            :source="previewSource"
        />
        <content-modal
            v-model="jsonModal"
            :content="finalJSON"
        />
        <load-modal
            v-model="loadModal"
            @load="loadJSON"
        />
    </v-card>
</template>

<script>
import {ref, onMounted} from 'vue'
import {componentList} from "@/config";
import dynamicalComponent from './dynamicalComponent'
import contentModal from './contentModal'
import previewModal from './previewModal'
import loadModal from './loadModal'
import dynamicalItem from './dynamicalItem'
import controller from '../controller'
import draggable from 'vuedraggable'
import {useToast} from "vue-toastification";

export default {
    name: "DynamicalWrapper",
    components: {
        dynamicalComponent,
        previewModal,
        controller,
        dynamicalItem,
        contentModal,
        loadModal,
        draggable
    },
    setup() {
        const toast = useToast();
        const componentPreview = ref(false)
        const jsonModal = ref(false)
        const loadModal = ref(false)
        const previewSource = ref('')
        const componentsList = ref(componentList)
        const components = ref([])
        const finalJSON = ref([])
        const activeEditProps = ref(false)
        const propsList = ref([{key: '', value: ''}])
        const openEditProps = () => {
            activeEditProps.value = true
        }
        const saveProps = () => {
            propsList.value.forEach(elem => {
                if (elem.key)
                    components.value.forEach(item => {
                        if (item.selected) {
                            item.data.elementProps[elem.key] = elem.value
                        }
                    })
            })
            activeEditProps.value = false
        }
        onMounted(() => {
            if (localStorage.getItem('components')) {
                components.value = JSON.parse(localStorage.getItem('components'))
            }
        })
        const addItem = (item) => {
            components.value.push(item)
        }
        const removeItem = (idx) => {
            components.value.splice(idx, 1)
        }
        const updateItem = (val, idx) => {
            components.value[idx] = val
        }
        const copyItem = (val, idx) => {
            components.value.splice(idx + 1, 0, val)
        }
        const editGroup = (i) => {
            console.log(i)
        }
        const selectAllStatus = ref(false)
        const selectAll = () => {
            if (selectAllStatus.value) {
                components.value.forEach(item => {
                    item.selected = true
                })
            } else {
                components.value.forEach(item => {
                    item.selected = false
                })
            }
        }
        const openPreview = (img) => {
            previewSource.value = img
            componentPreview.value = true
        }
        const generateJSON = () => {
            finalJSON.value = []
            components.value.forEach((item) => {
                finalJSON.value.push(item.data)
            })
            jsonModal.value = true
        }
        const loadJSON = (data) => {
            components.value = data
        }
        const saveLocalStorage = () => {
            if (components.value.length) {
                localStorage.setItem('components', JSON.stringify(components.value))
                toast.success("Сохранено!");
            } else {
                toast.error("Список компонентов пуст!");
            }
        }
        const clearItems = () => {
            components.value = []
            localStorage.removeItem('components')
        }
        return {
            activeEditProps,
            jsonModal,
            finalJSON,
            componentsList,
            componentPreview,
            previewSource,
            components,
            loadModal,
            selectAllStatus,
            propsList,
            clearItems,
            saveLocalStorage,
            loadJSON,
            addItem,
            updateItem,
            removeItem,
            copyItem,
            editGroup,
            selectAll,
            openPreview,
            generateJSON,
            saveProps,
            openEditProps
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
