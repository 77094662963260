<template>
    <v-card height="800">
        <v-row no-gutters style="height: 100%">
            <v-col cols="9" style="height: 100%; position: relative; overflow: auto;">
                <v-card-subtitle class="px-4 py-4 d-flex justify-space-between align-center">
                    Редактор Таба {{ curTab.label }}
                    <div class="d-flex">
                        <v-btn size="small" icon @click="loadModal = true">
                            <v-icon size="small">
                                mdi-upload
                            </v-icon>
                        </v-btn>
                        <v-btn size="small" icon class="ml-4" @click="saveLocalStorage">
                            <v-icon size="small">
                                mdi-content-save
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-subtitle>
                <v-divider/>
                <div class="py-4 px-4">
                    <div class="text-h7">Query</div>
                    <queryComponent
                        v-model="dataTabs.query"
                    />
                </div>
                <div class="py-4 px-4">
                    <div class="text-h7">SEO</div>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                label="Title"
                                hide-details
                                v-model="dataTabs.seo.title"
                            />
                        </v-col><v-col cols="4">
                        <v-text-field
                            label="description"
                            hide-details
                            v-model="dataTabs.seo.description"
                        />
                    </v-col>
                        <v-col cols="4">
                            <v-text-field
                                label="keywords"
                                hide-details
                                v-model="dataTabs.seo.keywords"
                            />
                        </v-col>
                    </v-row>
                </div>
                <div class="px-4 overflow-y-auto mb-16" v-if="activeTab" style="height: 100%">
                    <v-row class="d-flex align-center">
                        <v-col cols="4">
                            <v-text-field
                                class="my-3"
                                label="Label"
                                hide-details
                                clearable
                                v-model="curTab.label"
                            />
                        </v-col>
                        <v-btn
                            v-if="!curTab.to"
                            @click="addField(curTab,'to' , {name:'tabs-tab',params:{tabs:'',tab:''}})"
                        >
                            Добавить to.params
                        </v-btn>
                        <v-col cols="8" v-if="curTab.to">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-if="curTab.to.params.hasOwnProperty('tabs')"
                                        class="my-3"
                                        label="tabs"
                                        hide-details
                                        clearable
                                        v-model="curTab.to.params.tabs"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-if="curTab.to.params.hasOwnProperty('tab')"
                                        class="my-3"
                                        label="tab"
                                        hide-details
                                        clearable
                                        v-model="curTab.to.params.tab"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-btn v-if="!curTab.breadcrumbs" @click="addField(curTab,'breadcrumbs' , {})">Насыпать крошек</v-btn>
                            <v-expansion-panels v-if="curTab.hasOwnProperty('breadcrumbs')">
                                <v-expansion-panel>
                                    <v-expansion-panel-title>
                                        <div>Хлебные крошки</div>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-btn v-if="!curTab.breadcrumbs.tabs" @click="addField(curTab.breadcrumbs,'tabs' , {text: ''})">Добавить Tabs</v-btn>
                                                <v-text-field
                                                    v-if="curTab.breadcrumbs.hasOwnProperty('tabs')"
                                                    label="Tabs"
                                                    hide-details
                                                    clearable
                                                    v-model="curTab.breadcrumbs.tabs.text"/>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn v-if="!curTab.breadcrumbs.tab" @click="addField(curTab.breadcrumbs,'tab' , {text: ''})">Добавить Tab</v-btn>
                                                <v-text-field
                                                    v-if="curTab.breadcrumbs.hasOwnProperty('tab')"
                                                    label="Tab"
                                                    hide-details
                                                    clearable
                                                    v-model="curTab.breadcrumbs.tab.text"
                                                />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn v-if="!curTab.breadcrumbs.slug" @click="addField(curTab.breadcrumbs,'slug' , {text: ''})">Добавить Slug</v-btn>
                                                <v-text-field
                                                    v-if="curTab.breadcrumbs.hasOwnProperty('slug')"
                                                    label="Slug"
                                                    hide-details
                                                    clearable
                                                    v-model="curTab.breadcrumbs.slug.text"/>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                    <tabComponent
                        v-model="curTab.component"
                    />
                    <v-btn
                        class="my-3"
                        color="success"
                        @click="saveTab"
                    >
                        Сохранить
                    </v-btn>
                    <v-btn
                        class="my-3"
                        color="secondary"
                        @click="backTab"
                    >
                        Назад
                    </v-btn>
                </div>
                <controller
                    v-show="!activeTab"
                    @close="$emit('close')"
                    @generate="generateJSON"
                    @clear="clearItems"
                />
            </v-col>
            <v-divider vertical/>
            <v-col cols="3 d-flex flex-column" style="height: 100%">
                <v-card-subtitle class="v-card-subtitle px-4 py-4 d-flex align-center" style="height: 64px;">Табы:</v-card-subtitle>
                <v-divider/>
                <div class="py-4 px-4 overflow-y-auto">
                    <v-sheet
                        v-for="(tab, i) in dataTabs.tabs"
                        :key="i"
                        color="white"
                        elevation="2"
                        rounded
                        class="mb-4 pa-2 d-flex justify-space-between align-center flex-wrap"
                        :class="{'bg-primary': curTabNum === i ? 'bg-primary' : ''}"
                    >
                        <v-icon color="primary" v-if="!activeTab" @click="changeTab(tab, i)">mdi-pencil</v-icon>
                        <h4 class="pl-3 pr-2 py-1 font-weight-light d-flex justify-space-between align-center">
                            {{ tab.label }}
                        </h4>
                        <v-icon color="error" v-if="!activeTab" @click="deleteTab(i)">mdi-delete</v-icon>
                    </v-sheet>
                    <v-btn
                        elevation="2"
                        block
                        outlined
                        rounded="0"
                        color="primary"
                        v-if="!activeTab"
                        @click="addNewTab"
                    >
                        Добавить
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <load-modal
            v-model="loadModal"
            @load="loadJSON"
            :tabProps="true"
        />
        <content-modal
            v-model="jsonModal"
            :content="dataTabs"
        />
    </v-card>
</template>

<script>
import {onMounted, ref} from 'vue'
import {useToast} from 'vue-toastification'
import controller from '../controller'
import loadModal from "@/components/dynamical/loadModal";
import contentModal from "@/components/dynamical/contentModal";
import tabComponent from "@/components/tabs/tabComponent";
import queryComponent from "@/components/tabs/queryComponent";
export default {
    name: 'TheTabs',
    components: {
        tabComponent,
        contentModal,
        controller,
        loadModal,
        queryComponent
    },
    setup() {
        const toast = useToast();
        onMounted(() => {
            if (localStorage.getItem('tabs')) {
                dataTabs.value = JSON.parse(localStorage.getItem('tabs'))
            }
        })
        const activeTab = ref(false)
        const loadModal = ref(false)
        const curTabNum = ref(null)
        const curTab = ref({})
        const jsonModal = ref(false)
        const activeEditProps = ref(false)
        const dataTabs = ref({query:{}, tabs: [],seo: {title:'', description:'', keywords:''}})
        const tab = {
            "label": "",
            "breadcrumbs": {
                "tabs": {
                    "text": ""
                },
                "tab": {
                    "text": ""
                },
                "slug": {
                    "text": ""
                }
            },
            "component": {
                "path": "",
                "props": {}
            }
        }
        const addField = (source, key, val) => {
            return {...source[key] = val}
        }
        const changeTab = (editTab, i) => {
            activeTab.value = true
            curTabNum.value = i
            curTab.value = JSON.parse(JSON.stringify(editTab))
            if (Object.keys(JSON.parse(JSON.stringify(curTab.value))).length === 0) {
                curTab.value = JSON.parse(JSON.stringify(tab))
            }
            if (!Object.prototype.hasOwnProperty.call(curTab.value, 'component')) {
                curTab.value['component'] = { "path": "","props": {}}
            }
        }
        const addNewTab = () => {
            activeTab.value = true
            curTab.value = JSON.parse(JSON.stringify(tab))
            curTabNum.value = null
        }
        const saveTab = () => {
            clearEmpties(curTab.value)
            if (curTabNum.value !== null) {
                dataTabs.value.tabs.splice(curTabNum.value, 1, curTab.value)
            } else {
                dataTabs.value.tabs.push(curTab.value)
            }
            curTab.value = {}
            activeTab.value = false
            curTabNum.value = null
        }
        const backTab = () => {
            curTab.value = {}
            activeTab.value = false
            curTabNum.value = null
        }
        const deleteTab = (i) => {
            dataTabs.value.tabs.splice(i, 1)
        }
        const loadJSON = (data) => {
            dataTabs.value = data
        }
        function clearEmpties(tab) {
            for (let k in tab) {
                if (!tab[k] || typeof tab[k] !== "object") {
                    if (tab[k] === "") {
                        delete tab[k];
                    }
                    continue;
                }
                clearEmpties(tab[k]);
                if (Object.keys(tab[k]).length === 0) {
                    delete tab[k];
                }
            }
        }
        const generateJSON = () => {
            jsonModal.value = true
        }
        const saveLocalStorage = () => {
            if (dataTabs.value.tabs.length) {
                localStorage.setItem('tabs', JSON.stringify(dataTabs.value))
                toast.success("Сохранено!");
            } else {
                toast.error("Список компонентов пуст!");
            }
        }
        const clearItems = () => {
            dataTabs.value = {query:{},tabs: [],seo: {title:'', description:'', keywords:''}}
            localStorage.removeItem('tabs')
        }
        return {
            jsonModal,
            curTabNum,
            curTab,
            activeTab,
            tab,
            dataTabs,
            loadModal,
            activeEditProps,
            loadJSON,
            changeTab,
            addNewTab,
            saveTab,
            deleteTab,
            generateJSON,
            clearEmpties,
            backTab,
            addField,
            saveLocalStorage,
            clearItems

        }
    }
}
</script>