<template>
  <v-dialog
      v-model="active"
      width="500"
  >
    <v-card>
      <v-img
          max-height="200"
          min-height="200"
          width="500"
          :src="source"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="active = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed} from 'vue'
export default {
  name: "previewModal",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    source: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const active = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })
    return {
      active
    }
  }
}
</script>

<style scoped>

</style>
