<template>
  <v-app>
    <v-main class="app-content">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="12">
            <v-card-title class="px-0">Генератор JSON</v-card-title>
          </v-col>
          <v-col cols="12">
            <component
                v-if="currentComponent"
                :is="currentComponent"
                @close="currentComponent = ''"
            />
            <div
                v-else
                style="min-height: 80vh"
                class="d-flex align-center justify-center"
            >
              <v-btn
                  min-width="250"
                  class="mx-4"
                  @click="currentComponent = 'dynamical'"
              >
                Генератор страницы
              </v-btn>
              <v-btn
                  min-width="250"
                  class="mx-4"
                  @click="currentComponent = 'the-tabs'"
              >
                Генератор таба
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {ref} from 'vue'
import dynamical from "@/components/dynamical/dynamical";
import TheTabs from './components/tabs/TheTabs.vue'

export default {
  name: 'App',

  components: {
    dynamical,
    TheTabs
  },
  setup() {
    const currentComponent = ref('')

    return {
      currentComponent
    }
  }
}
</script>

<style>
.app-content {
  background-color: #F5F5F5;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

::-webkit-scrollbar-button {
  background-color: #222;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: #fff
}

::-webkit-scrollbar-track-piece {
  background-color: #696969
}

::-webkit-scrollbar-thumb {
  height: 40px;
  background-color: rgba(255,255,255,.3);
  border-radius: 0
}

::-webkit-scrollbar-corner {
  background-color: #fff;
  display: none
}

::-webkit-resizer {
  background-color: #fff;
  display: none
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px
}
body::-webkit-scrollbar-track-piece {
  background-color: #ddd
}
body::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: rgba(0,0,0,.4);
}
</style>
