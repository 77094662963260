<template>
  <v-dialog
      v-model="active"
      width="800"
  >
    <v-card width="800">
      <v-textarea
          v-model="data"
          label="Вставьте данные"
          rows="20"
      ></v-textarea>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
            variant="outlined"
            color="blue"
            @click="load"
        >
          Загрузить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="active = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, ref} from 'vue'
import { useToast } from "vue-toastification";
import {componentList} from "@/config";

export default {
  name: "contentModal",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    tabProps: {
        type: Boolean,
        default: false
    }
  },
  setup(props, {emit}) {
    const data = ref('')
    const toast = useToast();
    const active = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })
    const load = () => {
      let json = JSON.parse(data.value)
        if (props.tabProps) {
          emit('load', json)
            emit('update:modelValue', false)
            toast.success("Загружено!");
      } else {
          if (json) {
              let finalJson = []
              json.forEach(item => {
                  let elem = componentList.find(component => component.data.element === item.element)
                  finalJson.push({
                      img: elem.img,
                      label: elem.label,
                      data: {...elem.data, ...item}
                  })
              })
              emit('load', finalJson)
              emit('update:modelValue', false)
              toast.success("Загружено!");
          } else {
              toast.success("Не корректный JSON!");
          }
      }
    }
    return {
      data,
      active,
      load
    }
  }
}
</script>

<style scoped>

</style>
