<template>
    <v-select
        hide-details
        label="Путь"
        v-model="data.path"
        :items="paths"
    />
    <div class="pa-4">
        <v-btn
            v-if="!data.props && data.path && data.path.includes('nested') && type === 'topComponent'"
            @click="addField(data,'props' , {topFields: [], toggles: [], actions: {favorite: false,open: false,remove: false,history: false}})"
        >
            Добавить Props
        </v-btn>
        <v-btn
            v-if="!data.props && data.path && data.path.includes('nested') && type === 'contentComponent'"
            @click="addField(data,'props' , {config:{contents:[]}})"
        >
            Добавить Props
        </v-btn>
        <v-btn
            v-if="!data.props && data.path && data.path.includes('iterators') && type !== 'itemComponent'"
            @click="addField(data,'props' , {})"
        >
            Добавить props
        </v-btn>
        <v-btn
            v-if="!data.props && data.path && data.path.includes('iterators') && type === 'itemComponent'"
            @click="addField(data,'props' , {bottomFields:[], toggles:[], actions: {favorite: false,edit: false,open: false,remove: false}})"
        >
            Добавить props
        </v-btn>
    </div>
    <template v-if="data.hasOwnProperty('props') && type === 'itemComponent' && data.path && data.path.includes('iterators')">
        <v-btn
            class="py-4"
            v-if="!data.props.topFields"
            @click="addField(data.props,'topFields' , [])"
        >
            Добавить topFields
        </v-btn>
        <div v-if="data.props.hasOwnProperty('topFields')">
            <div class="text-h7 my-3">TopFields</div>
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    label="Дата создания"
                    :value='{"field": "date_create","tooltip": "Дата создания","icon": "plus-circle-outline"}'
                    v-model="data.props.topFields"
                />
                <v-checkbox
                    hide-details
                    label="Дата изменения"
                    :value='{"field": "date_change","tooltip": "Дата изменения","icon": "pencil-outline"}'
                    v-model="data.props.topFields"
                />
                <v-checkbox
                    hide-details
                    label="Кол-во просмотров"
                    :value='{"field": "views","tooltip": "Кол-во просмотров","icon": "eye-outline"}'
                    v-model="data.props.topFields"
                />
            </div>
        </div>
        <v-btn
            class="py-4"
            v-if="!data.props.bottomFields"
            @click="addField(data.props,'bottomFields' , [])"
        >
            Добавить bottomFields
        </v-btn>
        <div v-if="data.props.hasOwnProperty('bottomFields')">
            <div class="text-h7 my-3">BottomFields</div>
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    label="Адрес"
                    v-model="data.props.bottomFields"
                    :value='{"label": "Адрес", "field": "address"}'
                />
                <v-checkbox
                    hide-details
                    label="Цена"
                    v-model="data.props.bottomFields"
                    :value='{"label": "Цена", "field": "discount"}'
                />
                <v-checkbox
                    hide-details
                    label="Должность"
                    v-model="data.props.bottomFields"
                    :value='{"label": "Должность", "field": "department"}'
                />
                <v-checkbox
                    hide-details
                    label="ЧПУ"
                    v-model="data.props.bottomFields"
                    :value='{"label": "ЧПУ", "field": "url"}'
                />
                <v-checkbox
                    hide-details
                    label="Дата начала"
                    v-model="data.props.bottomFields"
                    :value='{"label": "Дата начала", "field": "date_start"}'
                />
                <v-checkbox
                    hide-details
                    label="Дата окончания"
                    v-model="data.props.bottomFields"
                    :value='{"label": "Дата окончания", "field": "date_end"}'
                />
            </div>
        </div>
        <v-btn
            class="py-4"
            v-if="!data.props.toggles"
            @click="addField(data.props,'toggles' , [])"
        >
            Добавить toggles
        </v-btn>
        <div v-if="data.props.hasOwnProperty('toggles')">
            <div class="text-h7 my-3">Toggles</div>
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    label="По умолчанию"
                    v-model="data.props.toggles"
                    :value='{"label": "По умолчанию", "field": "default"}'
                />
                <v-checkbox
                    hide-details
                    label="Обязательный МЦ"
                    v-model="data.props.toggles"
                    :value='{"label": "Обязательный МЦ", "field": "requireMC"}'
                />
                <v-checkbox
                    hide-details
                    label="Статус"
                    v-model="data.props.toggles"
                    :value='{"label": "Статус", "field": "status"}'
                />
            </div>
        </div>
        <div>
            <div class="text-h7 my-3" v-if="data.props.hasOwnProperty('actions')">Actions</div>
            <div class="d-flex align-center">
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('favorite')"
                    hide-details
                    label="favorite"
                    v-model="data.props.actions.favorite"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('open')"
                    hide-details
                    label="open"
                    v-model="data.props.actions.open"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('remove')"
                    hide-details
                    label="remove"
                    v-model="data.props.actions.remove"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('edit')"
                    hide-details
                    label="edit"
                    v-model="data.props.actions.edit"
                />
            </div>
        </div>
    </template>
    <template v-if="data.hasOwnProperty('props') && type !== 'itemComponent' && data.path && data.path.includes('iterators')">
        <div>
            <v-card-subtitle class="px-0">Пропсы:</v-card-subtitle>
            <v-code class="mb-2">
                {{ data.props }}
            </v-code>
            <v-btn text size="small" variant="outlined" @click="openEditProps(data.props)">
                <v-icon>mdi-pencil</v-icon>
                Редактировать пропсы
            </v-btn>
            <v-dialog
                v-model="activeEditProps"
                width="800"
            >
                <v-card width="800">
                    <v-card-title>Редактирование пропсов</v-card-title>
                    <v-sheet class="px-4 py-4">
                        <v-row no-gutters v-for="(item, i) in propsList" :key="i">
                            <v-col cols="4">
                                <v-text-field
                                    hide-details
                                    density="compact"
                                    v-model="item.key"
                                    label="Ключ"
                                    class="mb-2 mr-2"
                                ></v-text-field>
                            </v-col>
                            :
                            <v-col cols="4">
                                <v-text-field
                                    hide-details
                                    density="compact"
                                    v-model="item.value"
                                    label="Значение"
                                    class="mb-2 ml-2"
                                ></v-text-field>
                            </v-col>
                            <v-btn icon
                                   variant="outlined" color="green" class="ml-4" @click="propsList.push({key: '', value: ''})">
                                <v-icon color="green" >mdi-plus</v-icon>
                            </v-btn>
                            <v-btn icon
                                   @click="propsList.splice(i, 1)"
                                   variant="outlined" color="red" class="ml-4" v-if="i !== 0">
                                <v-icon color="red">mdi-minus</v-icon>
                            </v-btn>
                        </v-row>
                    </v-sheet>
                    <v-divider></v-divider>
                    <v-card-actions class="d-flex justify-space-between px-4 py-1">
                        <v-btn
                            variant="outlined"
                            color="blue"
                            @click="saveProps(null, 'iterators')"
                        >
                            Сохранить
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            @click="activeEditProps = false"
                        >
                            Закрыть
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </template>
    <template v-if="data.hasOwnProperty('props') && type === 'topComponent' && data.path && data.path.includes('nestedPages')">
        <v-btn
            class="py-4"
            v-if="!data.props.topFields"
            @click="addField(data.props,'topFields' , [])"
        >
            Добавить topFields
        </v-btn>
        <div v-if="data.props.hasOwnProperty('topFields')">
            <div class="text-h7 my-3">TopFields</div>
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    label="Дата создания"
                    :value='{"field": "date_create","tooltip": "Дата создания","icon": "plus-circle-outline"}'
                    v-model="data.props.topFields"
                />
                <v-checkbox
                    hide-details
                    label="Дата изменения"
                    :value='{"field": "date_change","tooltip": "Дата изменения","icon": "pencil-outline"}'
                    v-model="data.props.topFields"
                />
                <v-checkbox
                    hide-details
                    label="Кол-во просмотров"
                    :value='{"field": "views","tooltip": "Кол-во просмотров","icon": "eye-outline"}'
                    v-model="data.props.topFields"
                />
            </div>
        </div>
        <v-btn
            class="py-4"
            v-if="!data.props.toggles"
            @click="addField(data.props,'toggles' , [])"
        >
            Добавить toggles
        </v-btn>
        <div v-if="data.props.hasOwnProperty('toggles')">
            <div class="text-h7 my-3">Toggles</div>
            <div class="d-flex align-center">
                <v-checkbox
                    hide-details
                    label="По умолчанию"
                    v-model="data.props.toggles"
                    :value='{"label": "По умолчанию", "field": "default"}'
                />
                <v-checkbox
                    hide-details
                    label="Обязательный МЦ"
                    v-model="data.props.toggles"
                    :value='{"label": "Обязательный МЦ", "field": "requireMC"}'
                />
                <v-checkbox
                    hide-details
                    label="Статус"
                    v-model="data.props.toggles"
                    :value='{"label": "Статус", "field": "status"}'
                />
            </div>
        </div>
        <div>
            <div class="text-h7 my-3" v-if="data.props.hasOwnProperty('actions')">Actions</div>
            <div class="d-flex align-center">
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('favorite')"
                    hide-details
                    label="favorite"
                    v-model="data.props.actions.favorite"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('open')"
                    hide-details
                    label="open"
                    v-model="data.props.actions.open"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('remove')"
                    hide-details
                    label="remove"
                    v-model="data.props.actions.remove"
                />
                <v-checkbox
                    v-if="data.props.actions.hasOwnProperty('history')"
                    hide-details
                    label="history"
                    v-model="data.props.actions.history"
                />
            </div>
        </div>
    </template>
    <template v-if="data.hasOwnProperty('props') && type === 'contentComponent' && data.path && data.path.includes('nestedPages')">
        <v-expansion-panels>
            <v-expansion-panel v-for="(content, i) in data.props.config.contents" :key="i">
                <v-expansion-panel-title>
                    {{ content.label }}
                    <v-icon color="error" @click="data.props.config.contents.splice(i, 1)">mdi-delete</v-icon>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-select
                        hide-details
                        label="element"
                        :items="['subtitle', 'input', 'select', 'text', 'date', 'images', 'shortcode', 'editor', 'switch', 'time', 'spacer']"
                        v-model="content.element"
                    />
                    <v-text-field
                        hide-details
                        label="Название"
                        v-model="content.label"
                    />
                    <v-text-field
                        hide-details
                        label="key"
                        v-model="content.key"
                    />
                    <v-text-field
                        v-if="content.element === 'select'"
                        hide-details
                        label="itemsKey"
                        v-model="content.itemsKey"
                    />
                    <div>
                        <v-card-subtitle class="px-0">Пропсы:</v-card-subtitle>
                        <v-code class="mb-2">
                            {{ content.elementProps }}
                        </v-code>
                        <v-btn text size="small" variant="outlined" @click="openEditProps(content.elementProps)">
                            <v-icon>mdi-pencil</v-icon>
                            Редактировать пропсы
                        </v-btn>
                        <v-dialog
                            v-model="activeEditProps"
                            width="800"
                        >
                            <v-card width="800">
                                <v-card-title>Редактирование пропсов</v-card-title>
                                <v-sheet class="px-4 py-4">
                                    <v-row no-gutters v-for="(item, i) in propsList" :key="i">
                                        <v-col cols="4">
                                            <v-text-field
                                                hide-details
                                                density="compact"
                                                v-model="item.key"
                                                label="Ключ"
                                                class="mb-2 mr-2"
                                            ></v-text-field>
                                        </v-col>
                                        :
                                        <v-col cols="4">
                                            <v-text-field
                                                hide-details
                                                density="compact"
                                                v-model="item.value"
                                                label="Значение"
                                                class="mb-2 ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-btn icon
                                               variant="outlined" color="green" class="ml-4" @click="propsList.push({key: '', value: ''})">
                                            <v-icon color="green" >mdi-plus</v-icon>
                                        </v-btn>
                                        <v-btn icon
                                               @click="propsList.splice(i, 1)"
                                               variant="outlined" color="red" class="ml-4" v-if="i !== 0">
                                            <v-icon color="red">mdi-minus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-sheet>
                                <v-divider></v-divider>
                                <v-card-actions class="d-flex justify-space-between px-4 py-1">
                                    <v-btn
                                        variant="outlined"
                                        color="blue"
                                        @click="saveProps(i, 'nested')"
                                    >
                                        Сохранить
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="activeEditProps = false"
                                    >
                                        Закрыть
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                    <v-text-field
                        hide-details
                        label="col"
                        v-model="content.col"
                        type="number"
                    />
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn color="primary" @click="addContentConfig" class="my-3">
            Добавить элемент
        </v-btn>
    </template>
</template>

<script>
import {watch, toRef, ref} from "vue";

export default {
    name: "componentPart",
    props: {
        modelValue: {
          type: Object,
          default() {
              return {}
          }
      },
        paths: {
          type: Array,
            default() {
                return []
            }
        },
        type: {
            type: String,
            default: ''
        }
    },
    setup(props, {emit}) {
        const data = toRef(props, 'modelValue')
        const addContentConfig = () => {
            data.value.props.config.contents.push({
                element: "",
                label: "",
                key: "",
                col: "",
                elementProps: {}
            })
        }
        const propsList = ref([{key: '', value: ''}])
        const activeEditProps = ref(false)
        const openEditProps = (curProps) => {
            let props = {...curProps}
            if (Object.keys(props)) {
                for (let o in props) {
                    propsList.value.push({key: o, value: props[o]})
                }
            }
            activeEditProps.value = true
        }
        const saveProps = (i, type) => {
            if (type === 'iterators') {
                data.value.props = {}
                propsList.value.forEach(elem => {
                    if (elem.key) {
                        data.value.props[elem.key] = elem.value
                    }
                })
            } else if (type === 'nested') {
                propsList.value.forEach(elem => {
                    if (elem.key) {
                        data.value.props.config.contents[i].elementProps[elem.key] = elem.value
                    }
                })
            }
            propsList.value =[{key: '', value: ''}]
            activeEditProps.value = false
        }
        const addField = (source, key, val) => {
            return {...source[key] = val}
        }
        watch(
            () => data,
            (val) => {
                emit('update:modelValue', val)
            },
            { deep: true }
        )
        return {
            data,
            addContentConfig,
            openEditProps,
            saveProps,
            addField,
            activeEditProps,
            propsList
        }
    }
}
</script>

<style scoped>

</style>