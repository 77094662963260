<template>
  <v-dialog
      v-model="active"
      width="800"
  >
    <v-card>
      <v-sheet class="px-2 py-2">
        {{ content }}
      </v-sheet>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
            variant="outlined"
            color="blue"
            @click="copy"
        >
          Скопировать
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="active = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed} from 'vue'
import copyClipboard from "@/core/copyClipboard";
import { useToast } from "vue-toastification";

export default {
  name: "contentModal",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    content: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props, {emit}) {
    const toast = useToast();
    const active = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })
    const copy = () => {
      copyClipboard(JSON.stringify(props.content))
      toast.success("Сохранено!");
    }
    return {
      active,
      copy
    }
  }
}
</script>

<style scoped>

</style>
